<template>
<div style="width:100%">
  <v-dialog v-model="dialogoMail" width="800" persistent transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark :color="colores.primario">
        <v-btn icon dark @click="cerrarVenta"><v-icon>mdi-close</v-icon></v-btn>
        <v-toolbar-title>CORREO</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-4">
        SS
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn><v-icon :color="colores.primario">mdi-send</v-icon></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
export default {
  name: 'form_notificacionesComponent',
  components: {
  },
  props: ['dialogoMail', 'colores'],
  data: () => ({

  }),
  methods: {
    cerrarVenta () {
      this.$emit('closedNotificacion')
    }
  }
}
</script>
